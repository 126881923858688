import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import Gallery from './Components/Gallery';
import OurStories from './Components/OurStories';
import SiteSearch from './Components/SiteSearch';

// Example Component Registration
import HelloWorld from "./Components/HelloWorld"
const helloWorldComponent = document.getElementById('react');
if (helloWorldComponent) {
    ReactDOM.render(
        <HelloWorld />,
        helloWorldComponent
    );
}



const galleryElems = document.querySelectorAll('.image-gallery');
if (galleryElems.length) {
    galleryElems.forEach(function (elem) {
        let gallery_images = elem.dataset.gallery_images;
        if (gallery_images) {
            gallery_images = JSON.parse(gallery_images);
            const root = createRoot(elem);
            root.render(<Gallery galleryImages={gallery_images}/>);
        }

    })
}

const storyComponent = document.getElementById('storyContainer');
if (storyComponent) {
    const root = createRoot(storyComponent);
    root.render(<OurStories />);
}

const searchComponent = document.getElementById('siteSearch');
if (searchComponent) {
    const root = createRoot(searchComponent);
    root.render(<SiteSearch />);
}


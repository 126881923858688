import React, { useState, useEffect } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const Gallery = ({ galleryImages }) => {
	const [index, setIndex] = useState(-1);
	const [galleryPhotos, setGalleryPhotos] = useState([]);
	async function fetchImages() {
		setGalleryPhotos(galleryImages);
	}
	useEffect(() => {
		fetchImages();
	}, []);
	return (
		<>
			<PhotoAlbum photos={galleryPhotos} layout="rows" onClick={({ index }) => setIndex(index)} />
			<Lightbox
				slides={galleryPhotos}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen]}
			/>
		</>
	);
}
export default Gallery;
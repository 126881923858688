import React, { useState, useEffect } from "react";
// import { useHistory } from 'react-router-dom';
import Axios from "axios";
import ReactPaginate from 'react-paginate';


const SiteSearch = () => {

	// for filters
	const [posts, setPosts] = useState([]);
	const [loadingPost, setLoading] = useState(true);
	const [maxPages, setMaxPages] = useState(1);
	const [total, setTotal] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchText, setSearchText] = useState('');
	const [itemsPerPage, setItemsPerPage] = useState(12);
	const [resultText, setResultText] = useState('');
	const [config, setConfig] = useState({ params: {} });
	// think we need to add this one for intial page number set. I think demo is using pageCount?
	const [currentPage, setCurrentPage] = useState(0);

	// how does this work, how do you know this would work. where does PROVIDER come from?
	const pageTitle = typeof SEARCH !== 'undefined' && SEARCH?.searchPageTitle || 'Search';
	const searchApiUrl = typeof SEARCH !== 'undefined' && SEARCH?.api || '';
	const searchPlaceHolder = typeof SEARCH !== 'undefined' && SEARCH?.searchPlaceHolder || [];
	const searchButton = typeof SEARCH !== 'undefined' && SEARCH?.searchButton || 'Search';
	const noPosts = typeof SEARCH !== 'undefined' && SEARCH?.noPosts || '';

	const windowUrl = new URL(window.location);
	const getEndpoint = async (config) => {
		setLoading(true);
		const url = searchApiUrl + (currentPage + 1);
		const response = await Axios
			.get(url, config)
			.then((res) => res.data)
			.finally(() => {
				setLoading(false);

			})
		return response;
	};

	const fetchSearch = async () => {
		if (searchTerm.length) {
			const config = {
				params: {
					'search': searchTerm,
					'pages': currentPage + 1,
				}
			}
			setConfig(config);
			const searchResults = await getEndpoint(config);
			const { posts, total, maxPages, resultText } = searchResults;
			if (currentPage < 1) delete config.params.pages;
			var searchPosts = Object.values(posts);
			updateBrowserHistory(config);
			setTotal(total);
			setMaxPages(maxPages);
			setResultText(resultText);
			setPosts(searchPosts);
			scrollTo();
		}
	}
	async function watchSearch() {
		const queryParams = new URL(window.location).searchParams;
		let searchForText = searchTerm;
		let page = currentPage;
		queryParams.forEach((value, key) => {

			if (key === 'search' || key === 's') searchForText = value;

			if (key === 'pages') page = parseInt(value);
		})

		setSearchTerm(searchForText);
		setSearchText(searchForText);
		if (page > 1)
			setCurrentPage(page - 1);
	}
	// fetch postData
	useEffect(() => {
		if (loadingPost)
			watchSearch();
		setTimeout(() => {
			fetchSearch();
		}, 700);
	}, [currentPage, searchText]);

	const handleSearchChange = (value) => {
		setSearchTerm(value);
	}
	const handleFormSubmit = () => {
		event.preventDefault();
		setCurrentPage(0);
		setSearchText(searchTerm);
	}
	const updateBrowserHistory = (config) => {
		const searchParameters = new URLSearchParams()
		const params = config.params;
		if (Object.keys(params).length > 0) {
			for (const [key, value] of Object.entries(params)) {
				if (value || value.length) {
					searchParameters.set(key, value);
				}
			}
			let newUrl = "";
			if (searchParameters.toString()) newUrl = `?${searchParameters.toString()}`;
			const urlLocation = windowUrl.origin + windowUrl.pathname + newUrl;
			window.history.pushState({ path: urlLocation }, document.title, urlLocation);
		}
	}
	const handlePageClick = event => {
		let page = event.selected + 1;
		setCurrentPage(event.selected);
	};
	const scrollTo = () => {
		let scrollDiv = document.getElementById("header").offsetTop + 80;
		window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
	};

	return (
		<>
			<section className="search-bar container-narrow">
				<div className="search__container">
					<form id="search" onSubmit={evt => handleFormSubmit(evt)}>
						<input className="search-input" name="search" type="search" placeholder={searchPlaceHolder} value={searchTerm} onChange={(e) => handleSearchChange(e.target.value)} />
						<button type="submit" className="btn-red" onClick={evt => handleFormSubmit(evt)}>{searchButton}</button>
					</form>
				</div>
			</section>
			<section className="search-results container-medium ">
				{resultText && !loadingPost &&
					<p className="tag-black">{resultText}</p>
				}
				{loadingPost ? (
					<div className="loader-container">
						<div className="spinner">

						</div>
					</div>
				) : (
					(posts && posts.length)
						?
						posts.map((post, index) => (
							<div className="result block-flex" id={"post-" + post.id} key={post.id} >
								{post.image.src &&
									<div className="result-image__container">
										<a href={(post.permalink)} target={post.is_external ? "_blank" : "_self"} className="image-zoom" >
											<img src={(post.image.src) ? post.image.src : ''} alt={(post.title)} />
										</a>
									</div>
								}
								<div className="result-text__container stack-xs">
									<a className="tag-maroon-small" href={(post.tag.url)}>{(post.tag.title)}</a>
									<h2 className="h4-style">
										<a href={(post.permalink)} target={post.is_external ? "_blank" : "_self"} dangerouslySetInnerHTML={{ __html: post.title }}></a>
									</h2>
									{post.excerpt &&
										<p className="description">{(post.excerpt)}</p>
									}
								</div>
							</div>
						)

						) : (
							<div className="ProviderPost">
								<div className="Excerpt noresult-text" dangerouslySetInnerHTML={{ __html: noPosts }}></div>
							</div>
						)
				)
				}
				{(posts && posts.length) ? (
					<div className='pagination-element'>
						<ReactPaginate
							nextLabel=''
							onPageChange={handlePageClick}
							pageRangeDisplayed={3}
							marginPagesDisplayed={2}
							pageCount={maxPages}
							forcePage={currentPage}
							previousLabel=''
							pageClassName='page-item'
							pageLinkClassName='page-link'
							previousClassName='page-item'
							previousLinkClassName='page-link'
							nextClassName='page-item'
							nextLinkClassName='page-link'
							breakLabel='...'
							breakClassName='page-item'
							breakLinkClassName='page-link'
							containerClassName='pagination'
							activeClassName='active'
							renderOnZeroPageCount={null}
						/>
					</div>
				) : (
					''
				)}
			</section>
		</>
	);

};

export default SiteSearch;

import React, { useState, useEffect } from "react";
import Axios from "axios";
// import { useSearchParams } from "react-router-dom";

const OurStories = () => {

	// for filters
	const [filterParams, setFilterParams] = useState({});
	const [posts, setPosts] = useState([]);
	const [poststotal, setTotal] = useState(0);
	const [loadingPost, setLoading] = useState(true);
	const [showLoadBtn, setShowLoadBtn] = useState(false);
	const [audienceLabel, setAudienceLabel] = useState(typeof STORY !== 'undefined' && STORY?.audienceLabel || '')
	const [schoolLabel, setSchoolLabel] = useState(typeof STORY !== 'undefined' && STORY?.schoolLabel || '');
	const [contentLabel, setContentLabel] = useState(typeof STORY !== 'undefined' && STORY?.contentLabel || '');
	const [pageCount, setPageCount] = useState(1);
	// what plugin wants us to add
	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	// think we need to add this one for intial page number set. I think demo is using pageCount?
	const [page, setPage] = useState(1);
	const [paramAudience, setParamAudience] = useState(undefined);
	const [paramSchool, setParamSchool] = useState(undefined);
	const [paramCategory, setParamCategory] = useState(undefined);

	const [audienceToggle, setAudienceToggle] = useState(false)
	const [schoolToggle, setSchoolToggle] = useState(false)
	const [categoryToggle, setCategoryToggle] = useState(false)

	// how does this work, how do you know this would work. where does PROVIDER come from?
	const storyApiUrl = typeof STORY !== 'undefined' && STORY?.api || '';
	const storyCategories = typeof STORY !== 'undefined' && STORY?.categories || [];
	const storyAudiences = typeof STORY !== 'undefined' && STORY?.audiences || '';
	const storySchools = typeof STORY !== 'undefined' && STORY?.schools || '';
	const storyExpertise = typeof STORY !== 'undefined' && STORY?.expertise || '';
	const storyFilterTitle = typeof STORY !== 'undefined' && STORY?.filterTitle || '';
	const loadMoreLabel = typeof STORY !== 'undefined' && STORY?.loadMoreLabel || 'Load More Stories';
	const resetBtnLebel = typeof STORY !== 'undefined' && STORY?.resetBtnLebel || 'Reset all filters';
	const noPosts = typeof STORY !== 'undefined' && STORY?.noPosts || '';
	const windowUrl = new URL(window.location);
	const [config, setConfig] = useState({
		params: {
			'categories': '',
			'schools': '',
			'audiences': ''
		}
	});
	// posts displayed per page hard coded from which plugin uses as a prop on main component, so we hard code here?
	// const itemsPerPage = 10; // does it matter if we just do it this way?
	const [itemsPerPage, setItemsPerPage] = useState(12);
	// fetch postData

	const fetchStories = async (paged = null) => {
		paged = (paged || page);
		let url = storyApiUrl + paged;
		const response = await Axios.get(url, config).then(({ data }) => {
			const { posts: newPosts, total, maxPages } = data;
			setPosts(prevPosts => [...prevPosts, ...Object.values(newPosts)]);
			setTotal(total);
			setPageCount(maxPages);
			setCurrentItems(prevItems => prevItems + Object.values(newPosts).length);
			handlePageLoadBtn();
			if (Object.values(newPosts).length < itemsPerPage) {
				setShowLoadBtn(false);
			} else {
				setShowLoadBtn(true);
			}
		}).finally(() => {
			setLoading(false);
			setFilterDropdows();
			updateBrowserHistory();
			// scrollTo();
		});
		return response;
	}

	async function watchUrlParams() {
		const queryParams = new URL(window.location).searchParams;
		queryParams.forEach((value, key) => {
			config.params[key] = value
		})
		setConfig(config);
	}

	useEffect(() => {
		watchUrlParams();
		fetchStories();
		setTimeout(() => {
			handleFilterTrigger();
		}, 100);
	}, []);
	const updateBrowserHistory = () => {
		const { search } = config.params;
		const searchParameters = new URLSearchParams()
		const params = config.params;
		if (Object.keys(params).length > 0) {
			for (const [key, value] of Object.entries(params)) {
				if (value || value.length) {
					searchParameters.set(key, value);
				} else {
					searchParameters.delete(key);
				}
			}
			let newUrl = "";
			if (searchParameters.toString()) newUrl = `?${searchParameters.toString()}`;
			const urlLocation = windowUrl.origin + windowUrl.pathname + newUrl;
			window.history.pushState({ path: urlLocation }, document.title, urlLocation);
		}
	}
	const setFilterDropdows = () => {
		const params = config.params;

		if (Object.keys(params).length > 0) {
			for (const [key, value] of Object.entries(params)) {
				let selectedValues = [];
				// if (value || value.length) {
				let dropLists = document.querySelectorAll("[data-dropdown=" + key + "] li button");
				let currentValues = value ? value.split(',') : [];
				if (dropLists.length) {
					dropLists.forEach(option => {
						option.classList.remove('current');
						if (currentValues.includes(option.dataset.slug)) {
							selectedValues.push(option.dataset.value);
							option.classList.add('current');
						}
					})
				}
				// }
				if (key == 'categories') setParamCategory(selectedValues.join(', '));
				if (key == 'schools') setParamSchool(selectedValues.join(', '));
				if (key == 'audiences') setParamAudience(selectedValues.join(', '));
			}
		}

	};
	const handlePageLoadBtn = () => {
		const loadBtn = document.getElementById("loadMoreBtn");
		let paged = page + 1;
		setPage(paged);
	};
	const decodeHTMLEntities = text => {
		const el = decodeHTMLEntities.__cache_data_element
			= decodeHTMLEntities.__cache_data_element
			|| document.createElement('div');

		const enc = text
			.replace(/⪪/g, '⪪#')
			.replace(/&([a-z1-8]{2,31}|#x[0-9a-f]+|#\d+);/gi, '⪪$1⪫');

		el.textContent = enc;
		el.innerHTML = el.innerHTML
			.replace(/⪪([a-z1-8]{2,31}|#x[0-9a-f]+|#\d+)⪫/gi, '&$1;')
			.replace(/#⪫/g, '⪫');
		const dec = el.textContent;
		el.textContent = '';

		return dec;
	}

	let openDropdown = () => {
		const dropdownHeaders = document.querySelectorAll('.dropdown-header')

		dropdownHeaders.forEach(header => {
			header.addEventListener('click', function () {
			  const filterField = this.parentElement
	
			  // Close other dropdowns
			  const dropdownFields = document.querySelectorAll('.dropdown-column')
			  dropdownFields.forEach(field => {
				const dropdownHeader = field.querySelector('.custom-dropdown')
				const dropdownBox = field.querySelector('.dropdown-options')
				if (field !== filterField && dropdownHeader.classList.contains('active')) {
				  dropdownHeader.classList.remove('active')
				  dropdownHeader.setAttribute('aria-expanded', false)
				  dropdownBox.setAttribute('aria-hidden', true)
				}
			  })
			})
		  })
	}
	openDropdown()

	// handle submit and filter postData
	let handleFilterTrigger = () => {
		const filterDropdown = document.querySelector(".stories__filter");
		if (filterDropdown) {
			const dropdownOptions = document.querySelectorAll(".dropdown-options li button");
			dropdownOptions.forEach(option => {
				option.addEventListener("click", function (e) {
					e.preventDefault()
					this.classList.toggle('current')
					const currValue = this.dataset.value;
					const inptType = this.dataset.type;
					const dropdownMenu = document.getElementById(`${inptType}Dropdown`);
					if (currValue === 'all') {
						resetDropdownValues(dropdownMenu);
						config.params[inptType] = '';
					} else {
						config.params[inptType] = getDropdownValues(dropdownMenu);
					}
					setConfig(config);
					console.log(config)
					setPage(1);
					setPosts([])
					setTimeout(() => {
						fetchStories();
					}, 100);
				});
			});
		}
	};
	// handle submit and filter postData
	let resetFilterDropdowns = () => {
		const filterDropdown = document.querySelector(".stories__filter");
		if (filterDropdown) {
			const dropdownOptions = document.querySelectorAll(".dropdown-options li button");
			dropdownOptions.forEach(option => {

				const inptType = option.dataset.type;
				const dropdownMenu = document.getElementById(`${inptType}Dropdown`);
				resetDropdownValues(dropdownMenu);
				config.params[inptType] = '';
			});
		}
	};
	let getDropdownValues = (dropdownMenu) => {
		let dropdownOptions = dropdownMenu.querySelectorAll("li button");
		let currentValues = [];
		if (dropdownOptions.length) {
			dropdownOptions.forEach(option => {
				if (option.classList.contains('current')) {
					currentValues.push(option.dataset.slug);
				}
			});
		}
		return currentValues.join(',');
	};
	let resetDropdownValues = (dropdownMenu) => {
		let dropdownOptions = dropdownMenu.querySelectorAll("li button");
		let currentValues = [];
		if (dropdownOptions.length) {
			dropdownOptions.forEach(option => {
				option.classList.remove('current')
			});
		}
	};
	const resetFilters = (e) => {
		resetFilterDropdowns();
		setConfig({
			params: {
				'categories': '',
				'schools': '',
				'audiences': ''
			}
		})
		setPosts([])
		setTimeout(() => {
			fetchStories(1);
		}, 100);
	}
	// const scrollTo = () => {
	// 	let scrollDiv = document.getElementById("header").offsetTop+80;
	// 	window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
	// };

	const toggleAudienceDropDown = (e) => {
        e.preventDefault();
        setAudienceToggle((prevState) => !prevState);
    };

	const toggleSchoolDropDown = (e) => {
        e.preventDefault();
        setSchoolToggle((prevState) => !prevState);
    };

	const toggleCategoryDropDown = (e) => {
        e.preventDefault();
        setCategoryToggle((prevState) => !prevState);
    };


	return (
		<div className="wrapper">
			<div className="ProviderFilters">
				<div className="stories__filter" id="storyFilterForm">
					<form id="filterForm" className="block-flex" onSubmit={(e) => handleSubmit(e)}>
						<p className="tag-black">{storyFilterTitle}</p>
						<div className="filter-dropdowns block-flex">
									
							<div className="dropdown-column">
								<button type="button" className={`custom-dropdown ${audienceToggle ? "active" : ""}`} data-value={paramAudience} onClick={toggleAudienceDropDown} aria-expanded={audienceToggle ? "true" : "false"} aria-controls="listbox" aria-haspopup="listbox">
									<div className="dropdown-header">{paramAudience ? paramAudience : audienceLabel}</div>
								</button>
								<ul className="dropdown-options" id="audiencesDropdown" data-dropdown="audiences" aria-hidden={audienceToggle ? "false" : "true"}>
									<li><button type="button" data-value="all" data-type="audiences">All</button></li>
									{
										storyAudiences.map((option, index) =>
											<li key={`aud-${index}`}><button type="button" key={option.ID} data-type="audiences" data-value={option.name} data-slug={option.slug} data-id={option.ID}>{option.name}</button></li>
										)
									}
								</ul>
								</div>
									
								<div className="dropdown-column">
									<button type="button" className={`custom-dropdown ${schoolToggle ? "active" : ""}`} data-value={paramSchool} onClick={toggleSchoolDropDown} aria-expanded={schoolToggle ? "true" : "false"} aria-controls="listbox" aria-haspopup="listbox">
										<div className="dropdown-header" >{paramSchool ? paramSchool : schoolLabel}</div>
									</button>
								<ul className="dropdown-options" id="schoolsDropdown" aria-hidden={schoolToggle ? "false" : "true"} data-dropdown="schools">
									<li><button type="button" data-type="schools" data-value="all">All</button></li>
									{
										storySchools.map((option, index) =>
											<li key={`sch-${index}`}><button key={option.ID} type="button" data-type="schools" data-value={option.name} data-slug={option.slug} data-id={option.ID}>{option.name}</button></li>
										)
									}
								</ul>
								</div>
							
							<div className="dropdown-column">
								<button type="button" className={`custom-dropdown ${categoryToggle ? "active" : ""}`} data-value={paramCategory} onClick={toggleCategoryDropDown} aria-expanded={categoryToggle ? "true" : "false"} aria-controls="listbox" aria-haspopup="listbox">
									<div className="dropdown-header" >{paramCategory ? decodeHTMLEntities(paramCategory) : (contentLabel)}</div>
								</button>
							<ul className="dropdown-options" id="categoriesDropdown" aria-hidden={categoryToggle ? "false" : "true"} data-dropdown="categories">
								<li><button type="button" data-type="categories" data-value="all">All</button></li>
								{
									storyCategories.map((option, index) =>
										<li key={`cat-${index}`}><button key={option.ID} type="button" data-type="categories" data-value={option.name} data-slug={option.slug} dangerouslySetInnerHTML={{ __html: option.name }}></button></li>
									)
								}
							</ul>
							</div>
							
							
						</div>
					</form>
					<button className="tag-black reset-filters" onClick={(e) => { resetFilters(e) }}>
							{resetBtnLebel}
					</button>
				</div>

				<div className="stories__list block-flex" id="storiesPosts">
					{loadingPost ? (
						<div className="loader-container">
							<div className="spinner">

							</div>
						</div>
					) : (
						(posts && posts.length)
							?
							posts.map((post, index) => (
								<div className={post.image.src ? "stories__list__item stack-xs" : "stories__list__item stack-xs no-image"} id={"post-" + post.id} key={post.id} >
									<div className="stories__list__item__image">
										{post.image.src &&
											<a className="image-zoom" href={(post.permalink)} target={post.is_external ? "_blank" : "_self"}>
												<img src={post.image.src} alt="Logo" /></a>
										}
									</div>
									{post.cat_link.title &&
										<a className={post.image.src ? "tag-maroon-small" : "tag-gold"} href={post.cat_link.url} dangerouslySetInnerHTML={{ __html: post.cat_link.title }}></a>
									}
									<h2 className="item-title">
										<a href={(post.permalink)} target={post.is_external ? "_blank" : "_self"} dangerouslySetInnerHTML={{ __html: post.title }}></a>
									</h2>
								</div>
							)
						) : (
							<div className="ProviderPost">
									<div className="Excerpt noresult-text">{noPosts}</div>
								</div>
							)
					)
					}

				</div>
				<div className="stories__list block-flex">
					<button id="loadMoreBtn" className={showLoadBtn ? 'btn-gold' : 'btn-gold disabled'} onClick={(e) => fetchStories()}>{loadMoreLabel}</button>
				</div>
			</div>
		</div>
	);

};

export default OurStories;
